import React, {
    Fragment,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import { Field, Formik } from "formik";
import accounting from "accounting";
import * as yup from "yup";
import cx from "classnames";
import orderStyle from "./order.module.scss";

import { checkoutContext } from "../../context/checkoutContextProvider";
import OrderFormMutation from "./OrderFormMutation";
import {
    measuringPurchases,
    measuringViewOfProductDetail,
} from "../../utils/gtm";

import Button from "../Button";

import {
    Checkbox,
    CustomInputComponent,
    ProductInput,
    RadioButton,
    RadioButtonGroup,
} from "./CustomInput";

import {
    GOLD_MAX_AMOUNT_KELT,
    SILVER_MAX_AMOUNT_KELT,
    URLS,
    DEFAULT_DELIVERY_PRICE,
} from "../../constants";

import Modal from "../Modal/Modal";
import CheckoutModal from "../../components/CheckoutModal/CheckoutModal";

const initialState = {
    firstName: "",
    lastName: "",
    contactEmail: "",
    phone: "",
    type: "person",
    street: "",
    streetNumber: "",
    city: "",
    postalCode: "",
    country: "",
    gdpr: false,
    termsAndConditions: false,
    deliveryAddress: true,
    gold: 0,
    silver: 0,
    deliveryFirstName: "",
    deliveryLastName: "",
    deliveryContactEmail: "",
    deliveryPhone: "",
    deliveryStreet: "",
    deliveryNumber: "",
    deliveryCity: "",
    deliveryPostalCode: "",
    deliveryCountry: "",
    companyName: "",
    ic: "",
    dic: "",
    adviser: "",
};

const coinStringFormat = (amount, type) => {
    let string = "";
    switch (amount) {
        case 1:
            string = `1 ${type}ou minci`;
            break;
        case 2:
        case 3:
        case 4:
            string = `${amount} ${type}é mince`;
            break;
        default:
            string = `${amount} ${type}ých mincí`;
            break;
    }
    return string;
};

const orderSchema = validationStock =>
    yup
        .object()
        .shape({
            gold: yup
                .number()
                .min(0, "nelze objednat negativní počet mincí")
                .max(
                    validationStock.goldStock,
                    "lze objednat maximálně " +
                        coinStringFormat(validationStock.goldStock, "zlat")
                ),
            silver: yup
                .number()
                .min(0, "nelze objednat negativní počet mincí")
                .max(
                    validationStock.silverStock,
                    "lze objednat maximálně " +
                        coinStringFormat(validationStock.silverStock, "stříbr")
                ),

            firstName: yup.string().required("Vyplňte jméno"),
            lastName: yup.string().required("Vyplňte přijmení"),
            phone: yup
                .string()
                .max(13, "Telefonní číslo je moc dlouhé")
                .min(9, "Telefonní číslo je moc krátké")
                .required("Vyplňte tel. číslo"),
            contactEmail: yup
                .string()
                .email("Vyplňte e-mail adresu")
                .required("Vyplňte E-mail"),
            street: yup.string().required("Vyplňte ulici"),
            streetNumber: yup.string().required("Vyplňte čislo ulice"),
            city: yup.string().required("Vyplňte město"),
            postalCode: yup.string().required("Vyplňte psč"),
            deliveryAddress: yup.bool(),
            deliveryFirstName: yup.string().when(["deliveryAddress"], {
                is: false,
                then: yup.string().required("Vyplňte jméno"),
            }),
            deliveryLastName: yup.string().when(["deliveryAddress"], {
                is: false,
                then: yup.string().required("Vyplňte přijmení"),
            }),
            deliveryContactEmail: yup.string().when(["deliveryAddress"], {
                is: false,
                then: yup
                    .string()
                    .email("Vyplňte e-mail adresu")
                    .required("Vyplňte E-mail"),
            }),
            deliveryPhone: yup.string().when(["deliveryAddress"], {
                is: false,
                then: yup
                    .string()
                    .max(13, "Telefonní číslo je moc dlouhé")
                    .min(9, "Telefonní číslo je moc krátké")
                    .required("Vyplňte tel. číslo"),
            }),
            deliveryStreet: yup.string().when(["deliveryAddress"], {
                is: false,
                then: yup.string().required("Vyplňte ulici"),
            }),
            deliveryNumber: yup.string().when(["deliveryAddress"], {
                is: false,
                then: yup.string().required("Vyplňte čislo ulice"),
            }),
            deliveryCity: yup.string().when(["deliveryAddress"], {
                is: false,
                then: yup.string().required("Vyplňte město"),
            }),
            deliveryPostalCode: yup.string().when(["deliveryAddress"], {
                is: false,
                then: yup.string().required("Vyplňte psč"),
            }),

            companyName: yup.string().when(["type"], {
                is: "business",
                then: yup.string().required("Vyplňte jméno firmy"),
            }),
            ic: yup.string().when(["type"], {
                is: "business",
                then: yup.string().required("Vyplňte IČ"),
            }),
            dic: yup.string().when(["type"], {
                is: "business",
                then: yup.string().required("Vyplňte DIČ"),
            }),
            termsAndConditions: yup
                .bool()
                .oneOf(
                    [true],
                    "Musíte souhlasit s našimi Obchodními podmínkami"
                ),
            gdpr: yup
                .bool()
                .oneOf(
                    [true],
                    "Musíte souhlasit s našimi Zásadami zpracování osobních údajů"
                ),
        })
        .test(
            // this test is added additional to any other (build-in) tests
            "myCustomCheckboxTest",
            null, // we'll return error message ourself if needed
            obj => {
                // only testing the checkboxes here
                if (obj.gold !== 0 || obj.silver !== 0) {
                    return true; // everything is fine
                }
                return new yup.ValidationError(
                    "Vyberte, alespoň 1 minci pro dokončení objednávky",
                    null,
                    "noCoinSelected"
                );
            }
        );

const getDeliveryPrice = price => {
    if (price >= 50000 && price <= 100000) {
        return 190;
    }
    if (price > 100000) {
        return 860;
    }
    return 90;
};

const reducer = (state, action) => {
    switch (action.type) {
        case "gold":
            return {
                ...state,
                silverPrice: state.silverPrice,
                goldPrice: action.field.value * state.defaultGoldPrice,
                deliveryPrice: state.freeDelivery
                    ? 0
                    : getDeliveryPrice(
                          state.silverPrice +
                              action.field.value * state.defaultGoldPrice
                      ),
            };
        case "silver":
            return {
                ...state,
                silverPrice: action.field.value * state.defaultSilverPrice,
                goldPrice: state.goldPrice,
                deliveryPrice: state.freeDelivery
                    ? 0
                    : getDeliveryPrice(
                          state.goldPrice +
                              action.field.value * state.defaultSilverPrice
                      ),
            };
        default:
            throw new Error();
    }
};

const getCoinPrice = (products, type) =>
    products.find(coin => coin.coinType === type)
        ? parseInt(products.find(coin => coin.coinType === type).price, 10)
        : null;

const OrderForm = ({
    contactFormCondition,
    setOpen,
    executeScroll,
    stockLoading,
    stock,
    setStock,
    isCoinAvailable,
    setOrderId,
    emissionId,
    emissionTitle,
    coinProducts,
    freeDelivery,
}) => {
    const { adviser } = useContext(checkoutContext);

    const iniState = {
        silverPrice: 0,
        defaultSilverPrice: getCoinPrice(coinProducts, "silver"),
        goldPrice: 0,
        defaultGoldPrice: getCoinPrice(coinProducts, "gold"),
        deliveryPrice: freeDelivery ? 0 : DEFAULT_DELIVERY_PRICE,
        freeDelivery: freeDelivery,
    };

    const [state, dispatch] = useReducer(reducer, iniState);

    const productObj = [
        {
            productName: "Stříbrná sběratelská mince Kapitol", // Name or ID is required.
            itemId: "1",
            priceVat: getCoinPrice(coinProducts, "silver"),
            emitent: "kapitol",
        },
        {
            productName: "Zlatá sběratelská mince Kapitol", // Name or ID is required.
            itemId: "2",
            priceVat: getCoinPrice(coinProducts, "gold"),
            emitent: "kapitol",
        },
    ];

    useEffect(() => {
        measuringViewOfProductDetail(productObj);
    }, []);

    const formClasses = cx({
        [orderStyle.form]: true,
        [orderStyle.horizontal]: true,
    });

    const [submitError, setSubmitError] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [coinName, setCoinName] = useState("");
    const openModal = name => {
        setCoinName(name);
        setShowModal(true);
    };
    const closeModal = () => setShowModal(false);

    const sendMeasuringPurchases = (values, response) => {
        const cart = {};
        if (values.silver > 0) {
            cart.silver = {
                productName: "Stříbrná sběratelská mince Kapitol", // Name or ID is required.
                itemId: "1",
                priceVat: state.defaultSilverPrice,
                emitent: "kapitol",
                quantity: values.silver,
            };
        }
        if (values.gold > 0) {
            cart.gold = {
                productName: "Zlatá sběratelská mince Kapitol", // Name or ID is required.
                itemId: "2",
                priceVat: state.defaultGoldPrice,
                emitent: "kapitol",
                quantity: values.gold,
            };
        }
        const order = {
            orderId: 1,
            total: {
                totalPrice:
                    values.silver * state.defaultSilverPrice +
                    values.gold * state.defaultGoldPrice,
                delivery: 111,
            },
            cart,
        };
        measuringPurchases(order);
    };

    const validationStock = {
        goldStock:
            stock.goldStock < GOLD_MAX_AMOUNT_KELT
                ? stock.goldStock
                : GOLD_MAX_AMOUNT_KELT,
        silverStock:
            stock.silverStock < SILVER_MAX_AMOUNT_KELT
                ? stock.silverStock
                : SILVER_MAX_AMOUNT_KELT,
    };

    const setStockAfterPurchase = values => {
        setStock({
            ...stock,
            goldStock: stock.goldStock - values.gold,
            silverStock: stock.silverStock - values.silver,
        });
    };

    return (
        <>
            <Formik
                initialValues={initialState}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    values.adviser = adviser ? adviser : "";
                    values.emissionId = emissionId;

                    let response = await OrderFormMutation(values);

                    if (response && response.data.sendOrder.sugar === "true") {
                        resetForm(initialState);
                        setOpen(true);
                        setSubmitting(false);
                        executeScroll();
                        setOrderId(response.data.sendOrder.message);
                        sendMeasuringPurchases({
                            values,
                            ...response.data.sendOrder.message,
                        });
                        setStockAfterPurchase(values);
                    } else {
                        setSubmitError(true);
                        setSubmitting(false);
                    }
                }}
                validationSchema={orderSchema(validationStock)}
                render={props => (
                    <Fragment>
                        <form
                            onSubmit={props.handleSubmit}
                            className={formClasses}
                        >
                            {isCoinAvailable() && (
                                <>
                                    <div className={orderStyle.inline}>
                                        <Field
                                            name="firstName"
                                            component={CustomInputComponent}
                                            label="Jméno *"
                                            horizontal="horizontal"
                                        />
                                        <Field
                                            name="lastName"
                                            component={CustomInputComponent}
                                            label="Příjmení *"
                                            horizontal="horizontal"
                                        />
                                    </div>
                                    <div className={orderStyle.inline}>
                                        <Field
                                            name="contactEmail"
                                            component={CustomInputComponent}
                                            label="E-mail *"
                                            horizontal="horizontal"
                                        />
                                        <Field
                                            name="phone"
                                            component={CustomInputComponent}
                                            label="Telefonní číslo *"
                                            horizontal="horizontal"
                                        />
                                    </div>
                                    <div className={orderStyle.inline}>
                                        <RadioButtonGroup
                                            id="radioGroup"
                                            value={props.values.radioGroup}
                                            error={props.errors.radioGroup}
                                            touched={props.touched.radioGroup}
                                            label="FAKTURAČNÍ ÚDAJE"
                                        >
                                            <Field
                                                component={RadioButton}
                                                name="type"
                                                id="person"
                                                label="Soukromá osoba"
                                            />
                                            <Field
                                                component={RadioButton}
                                                name="type"
                                                id="business"
                                                label="Firma"
                                            />
                                        </RadioButtonGroup>
                                    </div>
                                    {props.values &&
                                        props.values.type == "business" && (
                                            <>
                                                <div
                                                    className={
                                                        orderStyle.inlineBig
                                                    }
                                                >
                                                    <Field
                                                        name="companyName"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        label="Jméno firmy *"
                                                    />
                                                    <div></div>
                                                </div>
                                                <div
                                                    className={
                                                        orderStyle.inline
                                                    }
                                                >
                                                    <Field
                                                        name="ic"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        label="IČ *"
                                                    />
                                                    <Field
                                                        name="dic"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        label="DIČ *"
                                                    />
                                                </div>
                                            </>
                                        )}
                                    <div className={orderStyle.inlineBig}>
                                        <Field
                                            name="street"
                                            component={CustomInputComponent}
                                            label="Ulice *"
                                        />
                                        <Field
                                            name="streetNumber"
                                            component={CustomInputComponent}
                                            label="Číslo popisné *"
                                        />
                                    </div>
                                    <div className={orderStyle.inlineBig}>
                                        <Field
                                            name="city"
                                            component={CustomInputComponent}
                                            label="Město *"
                                        />
                                        <Field
                                            name="postalCode"
                                            component={CustomInputComponent}
                                            label="Psč *"
                                        />
                                    </div>
                                    <div className={orderStyle.inline}>
                                        <div>
                                            <label className={orderStyle.label}>
                                                <span>Země</span>
                                            </label>
                                            <select
                                                name="country"
                                                className={orderStyle.select}
                                                value={props.values.country}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            >
                                                <option value="Česká republika">
                                                    Česká republika
                                                </option>
                                                <option value="Slovenská republika">
                                                    Slovenská republika
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={orderStyle.inlineFull}>
                                        <Field
                                            component={Checkbox}
                                            name="deliveryAddress"
                                            id="deliveryAddress"
                                            label="DODACÍ ADRESA"
                                        >
                                            <label htmlFor="deliveryAddress">
                                                {contactFormCondition}
                                                Dodací adresa je stejná jako
                                                fakturační
                                            </label>
                                        </Field>
                                    </div>
                                    {props.values &&
                                        props.values.deliveryAddress ===
                                            false && (
                                            <>
                                                <div
                                                    className={
                                                        orderStyle.inline
                                                    }
                                                >
                                                    <Field
                                                        name="deliveryFirstName"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        label="Jméno *"
                                                        horizontal="horizontal"
                                                    />
                                                    <Field
                                                        name="deliveryLastName"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        label="Příjmení *"
                                                        horizontal="horizontal"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        orderStyle.inline
                                                    }
                                                >
                                                    <Field
                                                        name="deliveryContactEmail"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        label="E-mail *"
                                                        horizontal="horizontal"
                                                    />
                                                    <Field
                                                        name="deliveryPhone"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        label="Telefonní číslo *"
                                                        horizontal="horizontal"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        orderStyle.inlineBig
                                                    }
                                                >
                                                    <Field
                                                        name="deliveryStreet"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        placeholder="Ulice *"
                                                        label="Ulice"
                                                    />
                                                    <Field
                                                        name="deliveryNumber"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        label="Číslo popisné *"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        orderStyle.inlineBig
                                                    }
                                                >
                                                    <Field
                                                        name="deliveryCity"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        label="Město *"
                                                    />
                                                    <Field
                                                        name="deliveryPostalCode"
                                                        component={
                                                            CustomInputComponent
                                                        }
                                                        label="psč *"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        orderStyle.inline
                                                    }
                                                >
                                                    <div>
                                                        <label
                                                            className={
                                                                orderStyle.label
                                                            }
                                                        >
                                                            <span>Země</span>
                                                        </label>
                                                        <Field
                                                            name="deliveryCountry"
                                                            component="select"
                                                            placeholder="Favorite Color"
                                                            className={
                                                                orderStyle.select
                                                            }
                                                        >
                                                            <option value="Česká republika">
                                                                Česká republika
                                                            </option>
                                                            <option value="Slovenská republika">
                                                                Slovenská
                                                                republika
                                                            </option>
                                                        </Field>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </>
                            )}
                            <div>
                                <div className={orderStyle.productsHeading}>
                                    <span>Produkt</span>
                                    <span>Množství</span>
                                    {isCoinAvailable() && (
                                        <span>Prodejní cena</span>
                                    )}
                                </div>
                                {coinProducts.map((coin, index) => (
                                    <Field
                                        key={coin.coinType}
                                        name={coin.coinType}
                                        component={ProductInput}
                                        placeholder=""
                                        label={`${coin.title} - ${coin.description}`}
                                        title={coin.title}
                                        description={coin.description}
                                        emissionTitle={emissionTitle}
                                        price={state[`${coin.coinType}Price`]}
                                        image={
                                            coin?.coinPicture?.imageFile
                                                ?.childImageSharp?.fluid
                                        }
                                        dispatch={dispatch}
                                        stock={stock[`${coin.coinType}Stock`]}
                                        openModal={openModal}
                                        stockLoading={stockLoading}
                                        isCoinAvailable={isCoinAvailable}
                                    />
                                ))}
                                {isCoinAvailable() && (
                                    <>
                                        {props.errors &&
                                            Object.keys(props.errors).length !==
                                                0 &&
                                            props.errors.constructor ===
                                                Object &&
                                            props.errors.noCoinSelected && (
                                                <div
                                                    className={orderStyle.error}
                                                >
                                                    {
                                                        props.errors
                                                            .noCoinSelected
                                                    }
                                                </div>
                                            )}
                                        <div className={orderStyle.shipping}>
                                            {!freeDelivery && (
                                                <>
                                                    <span>Doručení</span>

                                                    <span>
                                                        {accounting.formatNumber(
                                                            state.deliveryPrice,
                                                            0,
                                                            " ",
                                                            ","
                                                        )}
                                                        &nbsp;Kč
                                                    </span>
                                                </>
                                            )}
                                            {freeDelivery && (
                                                <span
                                                    className={
                                                        orderStyle.freeDelivery
                                                    }
                                                >
                                                    Doručení ZDARMA
                                                </span>
                                            )}
                                        </div>
                                        <div className={orderStyle.price}>
                                            <span>Celkem</span>
                                            <div className={orderStyle.total}>
                                                {accounting.formatNumber(
                                                    state.goldPrice +
                                                        state.silverPrice +
                                                        state.deliveryPrice,
                                                    0,
                                                    " ",
                                                    ","
                                                )}
                                                <span
                                                    className={
                                                        orderStyle.currency
                                                    }
                                                >
                                                    &nbsp;Kč
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {isCoinAvailable() && (
                                <>
                                    <div className={orderStyle.inlineFull}>
                                        <Field
                                            component={Checkbox}
                                            name="termsAndConditions"
                                            id="termsAndConditions"
                                        >
                                            <label htmlFor="termsAndConditions">
                                                Seznámil/a jsem se a souhlasím s{" "}
                                                <a
                                                    target={"_blank"}
                                                    rel={"noreferrer noopener"}
                                                    href={
                                                        URLS.termsAndConditions
                                                    }
                                                >
                                                    Obchodními podmínkami
                                                </a>{" "}
                                                společnosti KAPITOL, a.s.
                                            </label>
                                        </Field>
                                    </div>
                                    <div className={orderStyle.inlineFull}>
                                        <Field
                                            component={Checkbox}
                                            name="gdpr"
                                            id="gdpr"
                                        >
                                            <label htmlFor="gdpr">
                                                Seznámil/a jsem se se{" "}
                                                <a
                                                    target={"_blank"}
                                                    rel={"noreferrer noopener"}
                                                    href={URLS.gdpr}
                                                >
                                                    Zásadami zpracování osobních
                                                    údajů
                                                </a>{" "}
                                                společnosti KAPITOL, a.s.
                                            </label>
                                        </Field>
                                    </div>
                                    {submitError && (
                                        <div>
                                            Objednávku se nepodařilo odeslat
                                            prosím zkuste odeslat znovu nebo
                                            kontaktujte obchodní zastoupení.
                                        </div>
                                    )}
                                    {isCoinAvailable() && (
                                        <Button
                                            type="submit"
                                            disabled={props.isSubmitting}
                                            width={"order"}
                                            mt={20}
                                            data-test="order-button"
                                        >
                                            {props.isSubmitting
                                                ? "Odesílám…"
                                                : "Odeslat objednávku. Objednávka zákazníka zavazuje k platbě"}
                                        </Button>
                                    )}
                                </>
                            )}
                        </form>
                        {props.isSubmitting && <CheckoutModal />}
                    </Fragment>
                )}
            />
            <Modal
                isOpen={showModal}
                onRequestClose={() => closeModal()}
                type={"contact"}
                coinName={coinName}
                closeTimeoutMS={200}
            />
        </>
    );
};

export default OrderForm;
