const query = `mutation SEND_ORDER($input: SendOrderInput!) {
  sendOrder(input: $input) {
    status
    message
    sugar
  }
}
`;

export default async formData => {
    const graphqlUrl =
        process.env.WP_GRAPHQL_URI ||
        process.env.GATSBY_WP_GRAPHQL_URI ||
        "/graphql";

    const result = await fetch(graphqlUrl, {
        // eslint-disable-line no-undef
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            query,
            variables: {
                input: {
                    clientMutationId: "order",
                    emissionId: formData.emissionId,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    contactEmail: formData.contactEmail,
                    phone: formData.phone,
                    formType: formData.type,
                    street: formData.street,
                    streetNumber: formData.streetNumber,
                    city: formData.city,
                    postalCode: formData.postalCode,
                    country: formData.country,
                    deliveryAddress: formData.deliveryAddress,
                    silver: formData.silver,
                    gold: formData.gold,
                    deliveryFirstName: formData.deliveryFirstName,
                    deliveryLastName: formData.deliveryLastName,
                    deliveryContactEmail: formData.deliveryContactEmail,
                    deliveryPhone: formData.deliveryPhone,
                    deliveryStreet: formData.deliveryStreet,
                    deliveryNumber: formData.deliveryNumber,
                    deliveryCity: formData.deliveryCity,
                    deliveryPostalCode: formData.deliveryPostalCode,
                    deliveryCountry: formData.deliveryCountry,
                    termsAndConditionsCm: formData.termsAndConditionsCm,
                    gdprCm: formData.gdprCm,
                    termsAndConditions: formData.termsAndConditions,
                    gdpr: formData.gdpr,
                    companyName: formData.companyName,
                    ic: formData.ic,
                    dic: formData.dic,
                    adviser: formData.adviser,
                },
            },
        }),
    });
    return await result.json();
};
