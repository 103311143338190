import React from "react";

const Purity = () => (
    <svg
        width="34"
        height="32"
        viewBox="0 0 34 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.2796 8.09098H23.1505M9.94624 8.09098H12.6559M9.94624 12.005H17.5161M9.94624 15.9189H13.7312M9.94624 19.8329H13.7312M6.02857 25.2856V6.36974C6.02857 3.61207 8.24416 1.37146 10.971 1.37146H27.9714M27.9714 1.37146V25.6303C27.9714 27.8249 26.5683 29.692 24.621 30.363M27.9714 1.37146V6.40003H33C33 3.60152 30.7262 1.37146 27.9714 1.37146ZM6.02857 30.6286H23.029C23.5858 30.6286 24.1213 30.5352 24.621 30.363M22.3763 30.6286H5.86021C3.19355 30.6286 1 28.4351 1 25.7684V25.6824H21.3011V25.7684C21.3011 27.8898 22.7108 29.7066 24.621 30.363M23.1505 18.7577V22.1555L21.3011 21.2953L19.4516 22.1555V18.7577M23.9247 16.6501C23.9247 18.0695 22.7634 19.2308 21.3441 19.2308C19.9247 19.2308 18.7634 18.0695 18.7634 16.6501C18.7634 15.2308 19.9247 14.0695 21.3441 14.0695C22.7634 14.0695 23.9247 15.2308 23.9247 16.6501Z"
            stroke="#E0E5E7"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="square"
        />
    </svg>
);

export default Purity;
