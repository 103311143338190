import React from "react";

const BuyoutPossible = () => (
    <svg
        fill="none"
        height="32"
        viewBox="0 0 67 55"
        width="34"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g stroke="#E0E5E7" strokeWidth="2">
            <path d="m6.98202 21.6072h42.23298v5.893c0 .266.0968.4959.291.6905.1947.1946.425.2917.6909.2917.2866 0 .522-.0919.7055-.2762l9.8219-9.8215c.1835-.1841.2755-.4196.2755-.7057 0-.2867-.092-.5223-.2763-.706l-9.7901-9.79098c-.2457-.20489-.4915-.30702-.7372-.30702-.2862 0-.5217.09202-.7056.27606-.1839.18437-.2758.41936-.2758.70596v5.89298h-42.23278c-.26596 0-.49612.0971-.69048.2916-.19436.1944-.29154.4245-.29154.6904v5.893c0 .266.09707.4962.29154.6905.19447.1944.42452.2917.69048.2917z" />
            <path d="m60.0181 33.3938h-42.2326v-5.8932c0-.266-.0973-.4961-.2915-.6905-.1945-.1945-.4247-.2915-.6906-.2915-.2867 0-.5218.092-.706.276l-9.82134 9.821c-.18372.1847-.27606.4195-.27606.7065 0 .2656.09191.4911.27606.6746l9.79084 9.8218c.2457.2045.4912.3065.7368.3065.2659 0 .4961-.0971.6905-.291.1943-.1949.2915-.4246.2915-.6909v-5.8928h42.2324c.2664 0 .4959-.0967.6909-.2913.1942-.1947.291-.4246.291-.691v-5.8927c0-.2661-.0971-.4959-.291-.6902-.195-.1942-.4246-.2913-.6909-.2913z" />
        </g>
    </svg>
);

export default BuyoutPossible;
