import React from "react";
import Img from "gatsby-image";
import cx from "classnames";

import Button from "../Button";
import CoinHeaderBox from "./CoinHeaderBox";
import { isSilverCoinTheme } from "../../utils";
import style from "./coinHeader.module.sass";

const CoinHeader = ({
    title,
    content,
    headerSections,
    theme,
    images,
    stock,
    executeScroll,
    stockLoading,
}) => (
    <div
        className={cx(
            style.pageHeader,
            isSilverCoinTheme(theme) && style.pageHeaderSilver
        )}
    >
        <div className={style.pageHeader__left}>
            <div
                className={cx(
                    style.pageHeader__text,
                    isSilverCoinTheme(theme) && style.pageHeader__textSilver
                )}
            >
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                <Button
                    color={isSilverCoinTheme(theme) ? "gray-blue-dark" : "gold"}
                    width="big"
                    onClick={executeScroll}
                    data-test="buy-button"
                >
                    Koupit minci
                </Button>
            </div>
            <Img fluid={images?.medals} className={style.pageHeader__brick} />
        </div>

        <CoinHeaderBox
            title={title}
            headerSections={headerSections}
            theme={theme}
            images={images}
            stock={stock}
            stockLoading={stockLoading}
        />
    </div>
);

export default CoinHeader;
