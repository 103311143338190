import React from "react";

const Origin = () => (
    <svg
        width="24"
        height="36"
        viewBox="0 0 24 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <ellipse
            cx="11.7439"
            cy="31.5731"
            rx="1.79268"
            ry="1.79268"
            fill="#E0E5E7"
        />
        <rect
            x="0.819513"
            y="35.3268"
            width="34.6537"
            height="21.8488"
            transform="rotate(-90 0.819513 35.3268)"
            stroke="#E0E5E7"
            strokeWidth="1.2"
        />
        <rect
            x="3.38049"
            y="26.1073"
            width="22.8732"
            height="16.7268"
            transform="rotate(-90 3.38049 26.1073)"
            stroke="#E0E5E7"
            strokeWidth="1.2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.72438 9.65116L6.72438 19.6902L16.7634 19.6902L16.7634 16.9755L16.7634 11.8536L16.7634 9.65116L6.72438 9.65116ZM5.85365 20.5609L17.6341 20.5609L17.6341 16.9755L17.6341 11.8536L17.6341 8.78043L5.85365 8.78043L5.85365 20.5609Z"
            fill="#E0E5E7"
        />
        <line
            x1="4.82927"
            y1="14.839"
            x2="18.6585"
            y2="14.839"
            stroke="#E0E5E7"
            strokeWidth="1.2"
        />
    </svg>
);

export default Origin;
