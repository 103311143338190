import React, { useState } from "react";
import classNames from "classnames/bind";
import style from "./orderFormBox.module.sass";
import OrderForm from "./OrderForm";
import OrderInfoBox from "./OrderInfoBox";
import OrderConfirmation from "./OrderConfirmation";
import Title from "../Title/";

let cx = classNames.bind(style);

const OrderFormBox = ({
    images,
    executeScroll,
    stock,
    setStock,
    stockLoading,
    emissionId,
    emissionTitle,
    coinProducts,
    freeDelivery,
}) => {
    const [open, setOpen] = useState(false);
    const [orderId, setOrderId] = useState(null);

    const isCoinAvailable = () =>
        !stockLoading &&
        stock &&
        (Boolean(
            stock.goldStock &&
                stock.goldStock !== 0 &&
                stock.goldStock.length !== 0
        ) ||
            Boolean(
                stock.silverStock &&
                    stock.silverStock !== 0 &&
                    stock.silverStock.length !== 0
            ));

    const wrapperClassNames = cx({
        [style.wrapper]: true,
        [style.noCoinWidth]: !isCoinAvailable(),
    });

    return (
        <>
            {!open && (
                <>
                    <Title
                        color={"gold"}
                        pt={60}
                        mb={26}
                        type="h1"
                        transform={"transform"}
                        className={!isCoinAvailable() ? style.noCoinWidth : ""}
                    >
                        Objednávka
                    </Title>
                    <section className={wrapperClassNames}>
                        <div className={style.formBox}>
                            <OrderForm
                                setOpen={setOpen}
                                open={open}
                                executeScroll={executeScroll}
                                stock={stock}
                                setStock={setStock}
                                stockLoading={stockLoading}
                                isCoinAvailable={isCoinAvailable}
                                setOrderId={setOrderId}
                                coinProducts={coinProducts}
                                emissionId={emissionId}
                                emissionTitle={emissionTitle}
                                freeDelivery={freeDelivery}
                            />
                        </div>
                        {isCoinAvailable() && (
                            <div className={style.instruction}>
                                <OrderInfoBox freeDelivery={freeDelivery} />
                            </div>
                        )}
                    </section>
                </>
            )}
            {open && (
                <>
                    <Title
                        color={"gold"}
                        pt={60}
                        mb={80}
                        type="h1"
                        transform={"transform"}
                    >
                        <span data-test="confirmation">
                            Vaše objednávka byla úspěšně zpracována
                        </span>
                    </Title>
                    <OrderConfirmation images={images} orderId={orderId} />
                </>
            )}
        </>
    );
};
export default OrderFormBox;
