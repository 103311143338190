import React from "react";

import style from "./infoIcon.module.sass";

const InfoIcon = ({ data }) => (
    <ul className={style.infoIcons}>
        {data.map(item => (
            <li
                key={item.label}
                style={{ width: `${100 / data.length}%` }}
                className={style[item.name]}
            >
                <span className={style.icon}>{item.icon()}</span>
                {item.label}
            </li>
        ))}
    </ul>
);

export default InfoIcon;
