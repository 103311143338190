import React from "react";
import moment from "moment";
import { Link } from "gatsby";

import styles from "./coinPost.module.sass";

const CoinPost = ({ post }) =>
    post && (
        <>
            <ul className={styles.wrapper}>
                <li key={post.id}>
                    <Link to={`/zajimavosti-o-investicnich-kovech/${post.uri}`}>
                        {post.featuredImage ? (
                            <div
                                className={styles.featureImage}
                                style={{
                                    backgroundImage:
                                        "url(" +
                                        post.featuredImage.sourceUrl +
                                        ")",
                                }}
                            />
                        ) : (
                            <div className={styles.featureImageEmpty} />
                        )}

                        <div className={styles.content}>
                            <h3>{post.title}</h3>
                            <p className={styles.date}>
                                {moment(post.date).format("D. M. YYYY")}
                            </p>
                            <span className={styles.fakeLink}>
                                <span>Celý článek</span>
                                <span className={"icon-more-2"} />
                            </span>
                        </div>
                    </Link>
                </li>
            </ul>
        </>
    );

export default CoinPost;
