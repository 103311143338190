import React from "react";
import Title from "../Title";
import CoinSpinner from "../../components/EshopProducts/CoinSpinner";
import modalStyle from "./modal.module.sass";

const CheckoutModal = ({ setShowModal }) => (
    <div className={modalStyle.simpleModal}>
        <div className={modalStyle.boxModal}>
            <div className={modalStyle.simpleModalContent}>
                <CoinSpinner />
                <Title>Pracujeme na zpracování vaší objednávky.</Title>
            </div>
        </div>
    </div>
);

export default CheckoutModal;
