import React from "react";
import style from "./coinInfoDescription.module.sass";

import CoinPost from "./CoinPost";

const CoinInfoDescription = ({ article, nominalValue }) => (
    <section className={style.wrapper}>
        <div className={style.blog}>
            <h4>Z BLOGU</h4>
            <CoinPost post={article} />
        </div>
        <div className={style.info}>
            <h4>NOMINÁLNÍ HODNOTA</h4>
            <div
                dangerouslySetInnerHTML={{
                    __html: nominalValue,
                }}
            />
        </div>
    </section>
);
export default CoinInfoDescription;
