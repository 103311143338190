const query = `
    query GET_COIN_STOCK($id: ID!) {
        emission(id: $id) {
            coinProducts {
                coinType
                stock
                emission
            }
        }
    }
`;

export default async id => {
    const graphqlUrl =
        process.env.WP_GRAPHQL_URI ||
        process.env.GATSBY_WP_GRAPHQL_URI ||
        "/graphql";

    const result = await fetch(graphqlUrl, {
        // eslint-disable-line no-undef
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            query,
            variables: {
                id: id,
            },
        }),
    });
    return await result.json();
};
