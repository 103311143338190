import React from "react";
import style from "./orderInfoBox.module.sass";
import Title from "../Title/";

const OrderInfoBox = ({ freeDelivery }) => (
    <section className={style.wrapper}>
        <Title color={"gold"} mb={10} type="h2">
            Jak nakupovat
        </Title>
        <ol>
            <li>
                <h5>OBJEDNÁVKOVÝ FORMULÁŘ</h5>
                <p>
                    V&nbsp;objednávkovém formuláři vlevo si vyberte požadované
                    mince a&nbsp;vyplňte údaje.
                </p>
            </li>
            <li>
                <h5>ZAPLACENÍ OBJEDNÁVKY</h5>
                <p>
                    Po&nbsp;odeslání objednávky obdržíte e-mail
                    s&nbsp;potvrzením objednávky včetně platebních údajů. Objednávku
                    dokončíte úhradou bankovním převodem nejpozději do&nbsp;5&nbsp;pracovních
                    dní od&nbsp;zaslání potvrzení.
                </p>
            </li>
            <li>
                <h5>POTVRZENÍ PLATBY</h5>
                <p>
                    Po&nbsp;spárování vaší platby Vám zašleme potvrzovací e-mail
                    a&nbsp;zboží odešleme nejpozději do 5 pracovních dnů. 
                </p>
            </li>
            <li>
                <h5>DORUČENÍ</h5>
                <p>
                    Zásilky posíláme jako cenné psaní od&nbsp;České pošty,
                    obdržíte také kód pro&nbsp;sledování zásilky.
                </p>
            </li>

            {freeDelivery && (
                <li>
                    <h5>AKČNÍ NABÍDKA - DOPRAVA ZDARMA</h5>
                    <p>
                        Využijte akční nabídky dopravy ZDARMA a&nbsp;pořiďte si
                        limitovanou edici mincí KAPITOL se&nbsp;zajímavým
                        sběratelským potenciálem. Akce platí
                        až&nbsp;do&nbsp;odvolání.
                    </p>
                </li>
            )}
        </ol>
    </section>
);
export default OrderInfoBox;
