import React, { useState } from "react";
import classNames from "classnames";

import style from "./coinInfoBox.module.sass";
import logoMincovna from "../../images/cm-logo.svg";

import InfoIcons from "../../components/InfoIcons";
import Origin from "../icons/Origin";
import Purity from "../icons/Purity";
import BuyoutPossible from "../icons/BuyoutPossible";

let icons = [
    {
        name: "origin",
        icon: () => <Origin />,
        label: "Garance původu",
    },
    {
        name: "purity",
        icon: () => <Purity />,
        label: "Garance ryzosti",
    },
    {
        icon: () => <BuyoutPossible />,
        label: "Možnost výkupu",
    },
];

const CoinInfoBox = ({ specification }) => {
    const [open, setOpen] = useState(false);

    return (
        <section className={style.wrapper}>
            <div className={style.infoBox}>
                <img
                    src={logoMincovna}
                    alt="ceska mincovna logo"
                    className={style.image}
                />
                <p>
                    Česká mincovna je tradičním českým výrobcem a prodejcem
                    pamětních mincí a medailí. Přes dvacet let razí v Jablonci
                    nad Nisou oběžné mince pro český stát, při jejich přípravě
                    se uplatňují tradiční postupy, které čeští mincmistři a
                    rytci vybrušovali po staletí, i moderní technologie světové
                    úrovně.
                </p>
                <p>
                    Svým vznikem navázala na dlouholetou tradici ražby mincí na
                    území Čech, Moravy, Slezska a Slovenska.
                </p>
            </div>
            <div className={style.infoTableBox}>
                <InfoIcons data={icons} />
                <div className={style.infoTable}>
                    <h4
                        onClick={() => setOpen(!open)}
                        className={open ? style.open : ""}
                    >
                        SPECIFIKACE
                        <svg
                            width="25"
                            height="9"
                            viewBox="0 0 25 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 0.999999L12.5 7.64565L24 1"
                                stroke="#AD9752"
                                strokeWidth="2"
                                strokeMiterlimit="10"
                            />
                        </svg>
                    </h4>
                    <table className={open ? style.open : ""}>
                        <tbody>
                            <tr>
                                <th>Materiál</th>
                                {specification.map(({ material }) => (
                                    <th key={material}>{material}</th>
                                ))}
                            </tr>
                            <tr>
                                <th>Emisní náklad</th>
                                {specification.map(({ material, emission }) => (
                                    <td key={material}>{emission}</td>
                                ))}
                            </tr>
                            <tr>
                                <th>Hmotnost</th>
                                {specification.map(({ material, weight }) => (
                                    <td key={material}>{weight}</td>
                                ))}
                            </tr>
                            <tr>
                                <th>Ryzost</th>
                                {specification.map(({ material, purity }) => (
                                    <td key={material}>{purity}</td>
                                ))}
                            </tr>
                            <tr>
                                <th>Průměr</th>
                                {specification.map(({ material, diameter }) => (
                                    <td key={material}>{diameter}</td>
                                ))}
                            </tr>
                            <tr>
                                <th>Nominální hodnota</th>
                                {specification.map(
                                    ({ material, nominalValue }) => (
                                        <td key={material}>{nominalValue}</td>
                                    )
                                )}
                            </tr>
                            <tr>
                                <th>Prodejní cena</th>
                                {specification.map(
                                    ({ material, sellingPrice }) => (
                                        <td key={material}>{sellingPrice}</td>
                                    )
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                className={classNames({
                    [style.info]: true,
                    [style.open]: open,
                })}
            >
                <h4>NOMINÁLNÍ HODNOTA</h4>
                <p>
                    Pamětní mince KAPITOLu jsou legálním platidlem. Základním
                    atributem oficiálních peněz je nominální hodnota a pro
                    sběratelské mince KAPITOLu byl vybrán kamerunský frank.
                </p>
                <p>
                    Kamerunský frank byl zaveden do francouzských kolonií v
                    rovníkové Africe po 2. světové válce a v současnosti je
                    pevně navázán na euro. V rovníkové Africe tuto měnu
                    oficiálně využívá šest států a zaplatíte s ní v dalších
                    deseti sousedících státech.
                </p>
            </div>
        </section>
    );
};
export default CoinInfoBox;
