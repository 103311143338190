import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";

import Seo from "../components/Seo";
import Block from "../components/Block/";
import SiteLayout from "../components/SiteLayout";
import Breadcrumb from "../components/BreadCrumb/";
import CoinHeader from "../components/Eshop/CoinHeader";
import CoinInfoBox from "../components/Eshop/CoinInfoBox";
import CoinInfoDescription from "../components/Eshop/CoinInfoDescription";
import OrderFormBox from "../components/Eshop/OrderFormBox";
import SadSpendingBox from "../components/Eshop/SadSpendingBox";
import CoinStock from "../components/Eshop/CoinStock";

import styles from "../styles/pages.module.sass";
import { isSilverCoinTheme } from "../utils";

const getTheme = products => {
    if (products.findIndex(product => product.coinType === "gold") !== -1) {
        return "gold";
    } else {
        return "silver";
    }
};

const Coin = props => {
    const {
        data: {
            wpgraphql: { emission },
        },
    } = props;

    const {
        title,
        content,
        link,
        emissionId,
        nominalValue,
        freeDelivery,
    } = emission;

    const scrollToRef = ref =>
        window.scrollTo({ top: ref.current.offsetTop, behavior: "smooth" });
    const formRef = useRef(null);
    const executeScroll = () => scrollToRef(formRef);

    const [stock, setStock] = useState({});
    const [stockLoading, setStockLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            const result = await CoinStock(emission.id);
            let stock = {};
            result &&
                result.data.emission.coinProducts.forEach(element => {
                    stock[`${element.coinType}Stock`] = parseInt(
                        element.stock,
                        10
                    );
                    stock[`${element.coinType}Emission`] = parseInt(
                        element.emission,
                        10
                    );
                });
            setStock(stock);
            setStockLoading(false);
        }

        fetchData();
    }, []);

    const headerSections = {
        averz: emission?.sectionAverz,
        reverz: emission?.sectionReverz,
    };

    const theme = getTheme(emission.coinProducts);

    return (
        <SiteLayout
            location={props.location}
            coin
            coinTheme={theme}
            bgImage={emission?.emissionBackground?.sourceUrl}
        >
            <Seo
                title={`${emission.seo.title || title}`}
                description={`${emission.seo.metaDesc}`}
                image={emission.seo.opengraphImage}
                url={link}
            />
            <div>
                <Breadcrumb crumbs={[{ label: "E-shop", link: "eshop" }]} />
                <CoinHeader
                    title={title}
                    content={content}
                    headerSections={headerSections}
                    theme={theme}
                    images={{
                        backgroundImage:
                            emission?.emissionBackground?.imageFile
                                ?.childImageSharp?.fluid,
                        logoMincovna:
                            props.data.logoMincovna.childImageSharp.fixed,
                        coinFace:
                            headerSections?.averz?.coinImage?.imageFile
                                ?.childImageSharp?.fluid,
                        coinBack:
                            headerSections?.reverz?.coinImage?.imageFile
                                ?.childImageSharp?.fluid,
                        medals:
                            emission?.coinBox?.imageFile?.childImageSharp
                                ?.fluid,
                    }}
                    stock={stock}
                    executeScroll={executeScroll}
                    stockLoading={stockLoading}
                />
            </div>

            <Block
                background={true}
                coin={!isSilverCoinTheme(theme)}
                coinSilver={isSilverCoinTheme(theme)}
            >
                <CoinInfoBox specification={emission?.specification} />
            </Block>
            <div className={styles.reverseOnMobile}>
                <Block background={true}>
                    <CoinInfoDescription
                        article={emission?.selectedArticle}
                        nominalValue={nominalValue}
                    />
                </Block>
                <div ref={formRef}>
                    <Block background={true} radialGradientCoin={true}>
                        <OrderFormBox
                            images={{
                                medals:
                                    emission?.coinBox?.imageFile
                                        ?.childImageSharp?.fluid,
                            }}
                            executeScroll={executeScroll}
                            stock={stock}
                            setStock={setStock}
                            stockLoading={stockLoading}
                            coinProducts={emission?.coinProducts}
                            emissionId={emissionId}
                            emissionTitle={title}
                            freeDelivery={freeDelivery}
                        />
                    </Block>
                </div>
            </div>
            <Block grey={true}>
                <SadSpendingBox
                    images={{
                        spender: props.data.spender.childImageSharp.fixed,
                    }}
                />
            </Block>
        </SiteLayout>
    );
};

export default Coin;

export const pageQuery = graphql`
    query GET_COIN_PAGE($id: ID!) {
        wpgraphql {
            emission(id: $id) {
                id
                emissionId
                title
                content
                seo {
                    title
                    metaDesc
                    opengraphImage
                }
                sectionAverz {
                    coinImage {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 589, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    coinTitle
                    coinContent
                }
                sectionReverz {
                    coinImage {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 589, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    coinTitle
                    coinContent
                }
                selectedArticle {
                    id
                    title
                    uri
                    excerpt
                    date
                    featuredImage {
                        sourceUrl(size: MEDIUM)
                    }
                }
                nominalValue
                freeDelivery
                specification {
                    material
                    emission
                    weight
                    purity
                    diameter
                    nominalValue
                    sellingPrice
                }
                coinProducts {
                    title
                    description
                    price
                    coinType
                    coinPicture {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 60, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                coinBox {
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            fluid(maxWidth: 762, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                emissionBackground {
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            fluid(maxWidth: 1522, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        spender: file(relativePath: { eq: "spender.png" }) {
            childImageSharp {
                fixed(width: 388, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }

        logoMincovna: file(relativePath: { eq: "logo-mincovna-small.png" }) {
            childImageSharp {
                fixed(width: 131, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        logoMincovnaBig: file(relativePath: { eq: "logo-mincovna.png" }) {
            childImageSharp {
                fixed(width: 247, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;
