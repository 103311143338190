import React, { useState } from "react";
import Img from "gatsby-image";
import cx from "classnames";

import style from "./coinHeaderBox.module.sass";
import Title from "../Title";
import Button from "../Button";
import CoinSpinner from "../EshopProducts/CoinSpinner";
import { isSilverCoinTheme } from "../../utils";

const CoinHeaderBox = ({
    headerSections,
    theme,
    images,
    stock,
    stockLoading,
}) => {
    const [front, setFront] = useState(true);

    return (
        <section
            className={cx(
                style.wrapper,
                isSilverCoinTheme(theme) && style.wrapperSilver
            )}
        >
            <Img fixed={images.logoMincovna} className={style.image} />
            <Img
                fluid={images?.coinFace}
                className={style.image}
                style={{ display: front ? "block" : "none" }}
            />
            <Img
                fluid={images?.coinBack}
                className={style.image}
                style={{ display: !front ? "block" : "none" }}
            />
            <Button
                color="outline-silver"
                mt={5}
                onClick={e => {
                    setFront(!front);
                    document.activeElement.blur();
                }}
            >
                <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.005 6.59223L12.005 3.57465L9.00488 0.555243L7.94012 0.509766L7.95627 2.58644C4.10186 2.60001 0.981448 5.72879 0.981445 9.58639C0.981445 11.361 1.6431 12.9835 2.73171 14.2169L4.23118 12.8934C3.4527 12.0114 2.98145 10.8551 2.98145 9.58639C2.98145 6.82818 5.21483 4.59159 7.97183 4.5864L7.98742 6.59223L9.005 6.59223Z"
                        fill="#006470"
                    />
                    <path
                        d="M11.7737 6.66167C12.5522 7.54369 13.0234 8.69993 13.0234 9.96867C13.0234 12.7269 10.7901 14.9635 8.03306 14.9687L8.01746 12.9631H6.99988L3.99988 15.9807L7 19.0001L8.06476 19.0455L8.04861 16.9686C11.903 16.9551 15.0234 13.8263 15.0234 9.96867C15.0234 8.19408 14.3618 6.57158 13.2732 5.3382L11.7737 6.66167Z"
                        fill="#006470"
                    />
                </svg>
                OTOČIT
            </Button>
            <div className={style.textBox}>
                <span>{front ? "AVERZ" : "REVERZ"}</span>
                <Title
                    color={isSilverCoinTheme(theme) ? "grayBlueDark" : "gold"}
                    type={"h2"}
                    mb={20}
                >
                    {front
                        ? headerSections?.averz?.coinTitle
                        : headerSections?.reverz?.coinTitle}
                </Title>

                <div
                    dangerouslySetInnerHTML={{
                        __html: front
                            ? headerSections?.averz?.coinContent
                            : headerSections?.reverz?.coinContent,
                    }}
                />
            </div>
            <div
                className={cx(
                    style.coinState,
                    isSilverCoinTheme(theme) && style.coinStateSilver
                )}
            >
                <h4>STAV MINCÍ AKTUÁLNÍ EMISE</h4>

                <div className={style.coinInfo}>
                    {stock.goldEmission && (
                        <div className={style.withBorder}>
                            <div className={style.numbers}>
                                {stockLoading ? (
                                    <CoinSpinner color={"light"} />
                                ) : (
                                    <span className={style.rest}>
                                        {stock.goldStock === ""
                                            ? 0
                                            : stock.goldStock}
                                        <sup className={style.emision}>
                                            {" "}
                                            / {stock.goldEmission}
                                        </sup>
                                    </span>
                                )}
                            </div>
                            <span>Zlatých mincí</span>
                        </div>
                    )}
                    {stock.silverEmission && (
                        <div>
                            <div className={style.numbers}>
                                {stockLoading ? (
                                    <CoinSpinner color={"light"} />
                                ) : (
                                    <span className={style.rest}>
                                        {stock.silverStock === ""
                                            ? 0
                                            : stock.silverStock}
                                        <sup className={style.emision}>
                                            {" "}
                                            / {stock.silverEmission}
                                        </sup>
                                    </span>
                                )}
                            </div>
                            <span>Stříbrných mincí</span>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
export default CoinHeaderBox;
