import React from "react";
import style from "./orderConfirmation.module.sass";
import Img from "gatsby-image";

const OrderConfirmation = ({ images, orderId }) => (
    <section className={style.wrapper}>
        <div className={style.info}>
            <p className={style.confirmationTitle}>
                OBJEDNÁVKA <span>Č. {orderId}</span>
            </p>
            <p className={style.confirmationText}>
                byla úspěšně odeslána, nyní ji zpracováváme.
            </p>
            <p className={style.confirmationText}>
                Na e-mail vám přijde potvrzení o provedení platby, stejně tak
                jako informace o průběhu objednávky.
            </p>
        </div>
        <div className={style.image}>
            <Img fluid={images?.medals} />
        </div>
    </section>
);
export default OrderConfirmation;
